import React, { useState } from "react";
import { Text, Image } from "@atoms";
import { m } from "framer-motion";
import classNames from "classnames";
import t from "@utils/t";
import useLang from "@hooks/useLang";

const StickyPortalImage = ({ label, blocks, stationaryText }) => {
  const lang = useLang();
  const [active, setActive] = useState(blocks[0].uid);
  const textWithoutPuncuation = stationaryText.replace(
    // eslint-disable-next-line no-useless-escape
    /(~|`|!|@|#|$|%|^|&|\*|\(|\)|{|}|\[|\]|;|:|\"|'|<|,|\.|>|\?|\/|\\|\||-|_|\+|=)/g,
    ""
  );
  const regEx = new RegExp(textWithoutPuncuation, "gmi");

  return (
    <div className="texture-pattern relative z-20 bg-black">
      <div className="sticky bottom-0 top-0">
        <div className="absolute h-screen w-screen">
          {blocks.map(s => (
            <div key={s.uid} className={classNames("absolute inset-0")}>
              <div
                className={classNames(
                  "pointer-events-none absolute top-0 right-0 z-10 flex h-screen max-w-xs flex-col items-end justify-end gap-1 p-6 text-right text-white transition delay-300 duration-300 md:p-14",
                  { "opacity-0": active !== s.uid }
                )}
              >
                <Text variant="caption" clz>
                  <span>{t("Photo courtesy of", lang)}</span>
                  <span>{s.image.attribution}</span>
                </Text>
              </div>
              <div
                className={classNames(
                  "absolute inset-0 z-0 text-white transition duration-500",
                  {
                    "opacity-0": active !== s.uid,
                    "opacity-10": active === s.uid,
                  }
                )}
              >
                <Image ixParams={{ sat: -100 }} image={s.image} fill />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="relative z-10 mx-auto max-w-2xl px-6 py-24 md:py-28 md:pl-14 lg:py-32">
        {label && (
          <Text variant="lg" className="mb-14 font-bold uppercase text-white">
            {label}
          </Text>
        )}
        <div className="flex flex-col gap-14 py-14">
          {blocks
            ?.filter(s => !s.descriptor.match(regEx))
            ?.map((s, i) => {
              return (
                <div className="flex min-h-[30vh] flex-col gap-6 md:min-h-[50vh]">
                  <Text className="text-2xl font-bold uppercase leading-tight text-white md:text-4xl">
                    {s.descriptor.replace(regEx, "")}
                  </Text>
                  <m.div
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    viewport={{ margin: "-10% 0% -33% 0%" }}
                    onViewportEnter={() => setActive(s.uid)}
                  >
                    <Text variant="h2" className="text-white">
                      {stationaryText}
                    </Text>
                  </m.div>
                </div>
              );
            })}
          {blocks
            ?.filter(s => s.descriptor.match(regEx))
            ?.map(s => {
              // const { image, descriptor } = section;
              return (
                <div key={s.uid} className="flex w-full">
                  <Text className="prose text-2xl font-bold uppercase leading-tight text-white prose-h2:text-6xl md:text-4xl">
                    {s.descriptor}
                  </Text>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

StickyPortalImage.defaultProps = {};

export default StickyPortalImage;
